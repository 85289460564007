import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const Grid = styled.section`
display: grid;
grid-template-columns: repeat(4, 18.5vw);
grid-column-gap: 6vw;
margin-top: 7.5vh;
align-items: center;
@media (max-width: 599px) {
  grid-template-columns: repeat(2, 43vw);
  grid-column-gap: 6vw;
}
> div {
  position: relative;
}
.gatsby-image-wrapper {
  left: 50%;
  transform: translateX(-50%);
}
img {
    padding: 0 !important;
    max-height: 18.5vw;
    @media (max-width: 599px) {
      max-height: 43vw;
    }
  }
h3 {
  line-height: 1rem;
  font-size: .9125rem;
  letter-spacing: .75px;
  padding-top: 2vh;
  padding-bottom: 5vh;
  @media (max-width: 599px) {
    padding-bottom: 2.5vh;
    font-size: .875rem;
  }
}
a {
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  &:hover h3 {
    opacity: 1;
  }
  &:hover img {
    opacity: .75 !important;
  }
  h3 {
    opacity: 0;
    transition: all 0.3s ease;
    text-align: center;
  }
}
`

const Projectlist = () => {
    const data = useStaticQuery(graphql`
    {
        contentfulPage(title: {eq: "Work"}) {
          referencePages {
            ... on ContentfulProject {
              id
              projectTitle
              slug
              bannerImage {
                gatsbyImageData
              }
            }
          }
        }
      }      
    `)
    return (
        <Grid>
        {data.contentfulPage.referencePages.map(node => (
            <div key={node.id}>{ node.bannerImage && <Link to={`/project/${node.slug}`}><div><GatsbyImage image={node.bannerImage.gatsbyImageData} alt="image" objectFit="contain" /><h3>{node.projectTitle}</h3></div></Link>}</div>
        ))}
        </Grid>
    )
}

export default Projectlist